import React, { Fragment } from "react"

import "../styles/projectIdeal.scss"

import Contact from "../components/New-Contact-Form/new-contact-form"
import TestimonialBelt from "../components/Testimonial-Belt/testimonial-belt"

import { idealProject } from "../constants/new-ghost-projects"

import idealBanner from "../images/projects/ideal-1.png"
import idealPoster from "../images/projects/ideal-2.png"

const ProjectIdeal = () => {
  return (
    <Fragment>
      <div className="project-ideal-title">
        {/* <h1>Ideal Payments Integration</h1> */}
      </div>
      <div className="project-ideal-description">
        <p className="first-ideal-paragraph">{idealProject.longDescription1}</p>
        <p>{idealProject.longDescription2}</p>
      </div>
      <div className="project-ideal-main-container">
        <img
          className="ideal-poster-img"
          src={idealPoster}
          alt="Ideal App Poster"
        />
        <img src={idealBanner} alt="Ideal App Banner" />
      </div>
      <TestimonialBelt />
      <Contact />
    </Fragment>
  )
}

export default ProjectIdeal
